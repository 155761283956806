var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('simple-date-header',{attrs:{"date":_vm.translations.squads}}),_c('div',{staticClass:"px-4",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"d-flex justify-space-between mb-4"},[_c('div',{staticStyle:{"width":"300px"}},[_c('v-text-field',{attrs:{"hide-details":"","dense":"","placeholder":_vm.translations.filterBySquadName},model:{value:(_vm.squadFilterName),callback:function ($$v) {_vm.squadFilterName=$$v},expression:"squadFilterName"}})],1),_c('v-menu',{attrs:{"offset-y":"","bottom":"","close-on-click":false,"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white--text",attrs:{"color":"main","small":""}},'v-btn',attrs,false),on),[_vm._v(_vm._s(_vm.translations.newSquad))])]}}]),model:{value:(_vm.newSquadMenu),callback:function ($$v) {_vm.newSquadMenu=$$v},expression:"newSquadMenu"}},[_c('v-card',{staticClass:"pa-3",attrs:{"width":"350px"}},[_c('p',{staticClass:"ma-0 my-2"},[_vm._v(_vm._s(_vm.translations.whatNameForSquad))]),_c('v-text-field',{attrs:{"solo":"","hide-details":"","dense":"","height":"35px"},model:{value:(_vm.newSquadName),callback:function ($$v) {_vm.newSquadName=$$v},expression:"newSquadName"}}),_c('div',{staticClass:"d-flex justify-end mt-2"},[_c('v-btn',{staticClass:"white--text",attrs:{"color":"main","small":"","disabled":_vm.disableCreateBtn},on:{"click":_vm.createNewSquadHandler}},[_vm._v(_vm._s(_vm.translations.createNewSquad))])],1)],1)],1)],1),_c('v-row',{staticClass:"mb-4",attrs:{"no-gutters":""}},_vm._l((_vm.squadList),function(squad){return _c('v-col',{key:squad.id,attrs:{"cols":"auto"}},[_c('squad-card',{attrs:{"squad":squad},on:{"delete":function($event){return _vm.deleteSquadHandler(squad.id)},"drop-on-squad":function($event){return _vm.handleDrop(squad.id)},"remove-employee":function (employeeId) {
              _vm.addOrRemoveEmployee(employeeId, squad.id, 'remove');
            },"update":function (newName) {
              _vm.updateSquadHandler(newName, squad.id);
            }}})],1)}),1),(_vm.hasNextSquads)?_c('div',{staticClass:"d-flex justify-center"},[(_vm.hasNextSquads)?_c('v-progress-circular',{directives:[{name:"intersect",rawName:"v-intersect.quiet",value:(_vm.onIntersect),expression:"onIntersect",modifiers:{"quiet":true}}],attrs:{"indeterminate":"","color":"main","size":28}}):_vm._e()],1):_vm._e()],1),_c('v-menu',{attrs:{"offset-y":"","top":"","nudge-left":"","close-on-click":false,"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"position":"fixed","bottom":"0","right":"0"},attrs:{"color":"main ma-2","dark":"","fab":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiAccountSearch))])],1)]}}])},[_c('v-card',{staticClass:"card-lists-tabs"},[_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((['Addetti']),function(item){return _c('v-tab',{key:item},[_vm._v(" "+_vm._s(item)+" ")])}),1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[(_vm.$apollo.queries.availableEmployeesByEndDate.loading)?_c('v-card',[_c('v-card-title',{staticClass:"mb-0 pb-0"},[_vm._v(_vm._s(_vm.translations.employeesPlural))]),_c('div',{staticStyle:{"margin":"0 auto","width":"93%"}},[_c('v-skeleton-loader',{attrs:{"type":"image"}})],1)],1):_c('list',{staticClass:"list-style scroll-bar",attrs:{"data":_vm.filterEmployees,"type":"employee-all"},on:{"search":function (val) { return (_vm.employeeSearch = val); },"drag-item":function (data) { return _vm.dragItemFromList(data, 'employee'); }}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }